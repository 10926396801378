import { formatNumber } from '@/components/shared/utility/format';

export const clamp = (value: number) => Math.max(0, parseInt(value.toString()));

export const getValueCompareItem = (
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  item,
  sectionId: string,
  itemId: string,
  format?: string
) => {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const section = item?.[sectionId];
  const value = section?.[itemId];

  if (Array.isArray(value)) {
    // If value is an array, return it directly
    return value;
  }

  if (typeof value === 'object' && value !== null) {
    // If value is an object, collect values with keys like `${itemId}_${i}`
    const list: any[] = [];
    for (const key in value) {
      if (key.startsWith(`${itemId}_`)) {
        list.push(value[key]);
      }
    }
    return list;
  }

  if (typeof value === 'string') {
    const number = {
      gear: 3,
      steering: 1,
    };
    if (format && number[format as keyof typeof number]) {
      return formatNumber(value, number[format as keyof typeof number]);
    }
  }

  return value;
};

export const generateRandomId = (length = 10) => {
  const alphabet =
    '0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz';
  let id = '';
  const alphaLength = alphabet.length;

  for (let i = 0; i < length; i++) {
    id += alphabet.charAt(Math.floor(Math.random() * alphaLength));
  }

  const now = new Date();

  return `${now.valueOf()}-${id}`;
};

export function filterUniqueItems<T>(
  items: T[] = [],
  keySelector?: keyof T
): T[] {
  if (items.length === 0) return [];

  if (!keySelector) {
    return Array.from(new Set(items));
  }

  const newItems = new Set();

  return items.filter((item) => {
    const value = item?.[keySelector];

    if (newItems.has(value)) {
      return false;
    }
    newItems.add(value);
    return true;
  });
}
